.cat-container {
  display: flex;
  overflow-x: auto;
  gap: 5px;
  margin-bottom: 5px;
  background-color: #eff0eb;
}

.cat-container::-webkit-scrollbar {
  display: none;
}

.categoryName {
  max-width: 10vw;
}

.cat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cate {
  width: 99px;
  height: 99px;
  padding: 1px;
  margin: 4px;
  margin-bottom: 0px;
  text-align: center;
  background-color: rgb(137, 147, 147);
  border-width: 1px;
  border-color: rgb(0, 0, 0);
  border-radius: 50%;
  color: rgb(25, 28, 45);
  object-fit: cover;
}

@media only screen and (max-width: 1024px) {
  .cate {
    width: 65px;
    height: 65px;
  }

  .categoryName {
    max-width: 15vw;
  }
}

@media only screen and (max-width: 480px) {
 
  .cate {
    width: 65px;
    height: 65px;
  }

  .categoryName {
    max-width: 30vw;
  }
}
