.populerProductText {
  font-weight: bold;
  /* font-style: inherit; */
  color: #1e1e1e;
    font-size: medium;
}


.product-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-image {
  width: 100%;
  height: 15vh;

  border-radius: 10px;
  
  object-fit:contain;
  object-position: center;
}

.product-card {
  width: 43vw;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 5px 4px 0 rgba(37, 55, 38, 0.345);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  /* border-width: 10px;
  border-color: red; */
  border-radius: 10px;
  border: 0.5px solid rgb(181, 181, 181);
  text-align: center;
}


.product-name-style{
   color: #212121;
text-align: justify;
}

.product-price-style{
  color: #212121;
  font-weight: bold;
}



.check {
  top: -10px;
  color: #ffffff;
}

@media only screen and (min-width: 1024px) {
 

  .product-image {
    /* width:22vw; */
    height: 30vh;
   
  }

  .product-card {
    width: 22vw;
    
  }
}
/* 
@media only screen and (max-width:600px){
  .product-card-container{
    gap: 10px;
  }
  .product-image {
    width: 40vw;
    height: 10vh;
  }

  .product-card {
    width: 44vw;
  }
} */


h4, h6, p{
  margin: 5px;
}

.texthh:hover{
 cursor: pointer;
 background: transparent;
}

