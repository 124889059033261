/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
body {
  margin: 10;
font-family: 'Rubik', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.click:hover{
  cursor: pointer;
}
