.view-more-details-page{
  font-weight: 500;
  font-size: 16px;
  padding-inline-start: 10px;
  line-height: 30px;

 cursor: pointer;
}

.product-name-details-page {
  color: black;
  font-size: 20px;
  font-weight: 500;
  max-width: 100vw;
  width: fit-content;
  padding-inline-start: 10px;
  line-height: 30px;

}

.product-price-details-page {
  margin-top: 15px;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: bold;
  max-width: 30vw;
  width: fit-content;
  padding-inline-start: 10px;
  line-height: 30px;

}

.highlightclass{
  white-space: pre-line;
}

::-webkit-scrollbar{
    display: none;
}